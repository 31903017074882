<template>
  <form v-if="user" @submit.prevent="doUpdateProfile">
    <v-row>
      <v-col cols="12">
        <v-card-title>
          <v-avatar size="64" class="rounded-circle elevation-1 d-inline-block">
            <drawer-avatar-img :showCameraIcon="true" style="cursor: pointer" />
          </v-avatar>
          <v-spacer></v-spacer>
          <v-btn class="primary darken-2" dark type="submit">
            <v-icon left>mdi-floppy</v-icon>
            {{ $lang("Save") }}
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" md="6">
        <v-card-text>
          <update-username />
        </v-card-text>
        <v-text-field
          :label="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_FIRSTNAME-LABEL')"
          :hint="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_FIRSTNAME-HINT')"
          v-model="profile.first_name"
          type="text"
          :error-messages="errors && errors.firstname ? errors.firstname : []"
        ></v-text-field>

        <v-text-field
          :label="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_LASTNAME-LABEL')"
          :hint="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_LASTNAME-HINT')"
          v-model="profile.last_name"
          type="text"
          :error-messages="errors && errors.last_name ? errors.last_name : []"
        ></v-text-field>
        <dob-selector :profile="profile" :errors="errors" />
        <v-radio-group
          :label="$lang('gender')"
          prepend-icon="mdi-gender-male-female"
          hint="Choose a state you live in."
          v-model="profile.gender"
          :error-messages="errors && errors.gender ? errors.gender : []"
        >
          <v-radio
            v-for="itm in genders"
            :key="itm.value"
            :label="itm.text"
            :value="itm.value"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="profile.state"
          hint="Choose an Indian state you live in."
          :items="states"
          :label="$lang('State')"
          prepend-icon="mdi-flag"
          :error-messages="errors && errors.state ? errors.state : []"
        />
        <v-text-field
          :label="$lang('City')"
          v-model="profile.city"
          type="text"
          prepend-icon="mdi-city"
          placeholder="Type your city name here"
          :error-messages="errors && errors.city ? errors.city : []"
        ></v-text-field>
        <vue-tip
          variant="minimal"
          :initial="profile.about"
          @changed="
            (val) => {
              profile.about = val;
            }
          "
        />
      </v-col>
      <v-col cols="12">
        <v-card-actions>
          <v-btn class="primary darken-2" dark type="submit">
            <v-icon left>mdi-floppy</v-icon>
            {{ $lang("Save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.push({ name: 'home' })">{{
            $lang("AUTH:UPDATE_PROFILE:BUTTON_SECONDARY-TEXT")
          }}</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueTip from "@/components/VueTip";
import DrawerAvatarImg from "@/components/DrawerAvatarImg";
import UpdateUsername from "../modules/UpdateUsername";
import DobSelector from "../modules/DobSelector.vue";
import CountryList from "@/helpers/CountryList";
import IndianStateList from "@/helpers/IndianStateList";

export default {
  name: "update-profile",
  components: {
    VueTip,
    DrawerAvatarImg,
    UpdateUsername,
    DobSelector,
  },
  data() {
    return {
      profile: {},
      genders: [
        { text: "Male", value: "male" },
        { text: "Female", value: "female" },
        { text: "Other", value: "other" },
      ],
      states: IndianStateList,
      countries: CountryList,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      user: "auth/user",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  watch: {
    user(value) {
      this.profile = value.profile;
    },
  },
  mounted() {
    if (this.user) this.profile = this.user.profile;
  },
  methods: {
    ...mapActions({
      updateProfile: "auth/updateProfile",
    }),
    doUpdateProfile() {
      if (!this.profile) return;
      this.updateProfile({
        profile: this.profile,
        context: this,
      })
        .then((res) => {})
        .catch((err) => {});
    },
  },
};
</script>
