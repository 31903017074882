<template>
  <form v-if="authUser" @submit.prevent="doUpdateUsername">
    <v-text-field
      :label="isHindi ? 'आपका यूजरनेम' : 'Your username'"
      :loading="loading"
      :disabled="loading"
      outlined
      :hint="`vikarn.com/${username ? username : '?????'}`"
      v-model="username"
      append-icon="mdi-floppy"
      @blur="doUpdateUsername"
      @click:append="doUpdateUsername"
      @keydown.enter="doUpdateUsername"
      :error-messages="errors && errors.username ? errors.username : []"
    />
  </form>
</template>

<script>
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import appRoutes from "../../routes";
import { flattenArray } from "@/helpers/utils";
import { mapGetters } from "vuex";

export default {
  name: "update-username",
  components: {},
  data() {
    return {
      username: null,
      loading: false,
      unsafeNames: null,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
  },
  watch: {
    authUser(val) {
      this.username = val.username;
    },
  },
  mounted() {
    if (this.authUser) {
      this.username = this.authUser.username;
    }
  },
  methods: {
    doUpdateUsername() {
      if (
        this.loading ||
        !this.unsafeNames ||
        !this.username ||
        !this.username.trim() ||
        this.username.trim().length < 1
      ) {
        return;
      }

      this.loading = true;

      if (this.username == this.authUser.username) {
        this.loading = false;
        return;
      }
      if (this.unsafeNames.includes(this.username.trim().toLowerCase())) {
        this.loading = false;
        this.$store.dispatch("setAlert", {
          message: "this username is reserved. choose something else.",
          type: "orange darken-3 white--text",
        });
        return;
      }

      return axios
        .post("auth/update/username", {
          username: this.username.trim().toLowerCase(),
        })
        .then((res) => {
          this.$store.dispatch("auth/fetchUser").then((res) => {
            this.username = this.authUser.username;
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
          // bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.unsafeNames = flattenArray(
      appRoutes.map((el) =>
        el.path.split("/").filter((em) => em.length || em == "*")
      )
    );
    this.unsafeNames.map((el) => el.toLowerCase());
  },
};
</script>
